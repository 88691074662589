::-webkit-scrollbar {
  width: 6px;
}

/* ::-webkit-scrollbar-track:hover {
    background-color: #E3E3E3;
  } */

::-webkit-scrollbar-thumb:hover {
  background-color: #e3e3e3;
  border-radius: 5px;
}
