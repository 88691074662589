#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 300px;
  height: 300px;
  /* padding: 50px; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 200%;
}

.swiper-pagination {
  --swiper-pagination-bottom: -25px;
  --swiper-pagination-bullet-width: 20px;
  --swiper-pagination-bullet-height: 7px;
  --swiper-pagination-bullet-border-radius: 15px;
  --swiper-pagination-bullet-inactive-color: #9ebdfb;
  --swiper-pagination-color: #ffffff;
}

.new_bg {
  background: linear-gradient(142deg, #3277ff 2.42%, #142c5a 192.57%);
}
